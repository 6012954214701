import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit {

  resumeData:any;
  isLoaded = false;
  loadedUrl = '';

  constructor(private http:HttpClient, private route:ActivatedRoute) { 
    this.route.params.subscribe(p => {
      this.loadedUrl = `https://us-central1-npopinth.cloudfunctions.net/resume?id=${p['id']}`;
    })
  }


  async ngOnInit() {
    //https://us-central1-npopinth.cloudfunctions.net/resume?id=rec3XMrLITeuOGsFD
    this.resumeData = await this.http.get(this.loadedUrl).toPromise();
    console.log(this.resumeData)
    this.isLoaded = true;
  }

}
