import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from "./home/home.component"
import { ResumeComponent } from "./resume/resume.component"
import { PortfolioComponent } from './portfolio/portfolio.component'

const routes: Routes = [
  { path:'', component:HomeComponent},
  { path:'resume/:id', component:ResumeComponent},
  { path:'portfolio/:view', component:PortfolioComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
