import { Component, OnInit } from '@angular/core';
const SPEED = 30;
@Component({
  selector: 'terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css']
})
export class TerminalComponent implements OnInit {


  message:string

  constructor() { }

  ngOnInit() {
    var i = 0;
    var txt = `npm start
              > ng serve
              ** Server is now live! **
              A developer by trait, music nerd by choice. Please reach out to me if you are interested to...

              - Hire me as Freelancer
              - Consult me about Airtable
              - Just want to connect
              
              Have a good day :)
              `;
    
    function typeItOut () {
      if (i < txt.length) {
        document.getElementsByClassName('demo')[0].innerHTML += txt.charAt(i);
        i++;
        setTimeout(typeItOut, SPEED);
      }
    }

    setTimeout(typeItOut, 1800);
  }

}
