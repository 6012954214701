import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  isShow = false;

  position:number;
  email:string;
  isSent = false;
  
  mainState:false|"line"|"web" | "airtable" = false;

  ngOnInit() {
  }

  select(state:"line"|"web"|"airtable") {
    this.mainState = state;
    if(this.mainState == 'line'){
      this.position = 33
    }else if (this.mainState == 'web'){
      this.position = 0;
    }else{
      this.position = 66;
    }

    document.getElementsByClassName('')
  }

  toggle(){
    this.isShow = !this.isShow;
  }

  resolved(event){
    console.log('resolve', event)
  }

  subcribe(){
    this.email = document.getElementsByName('email')[0]['value'];
    this.isSent = true;
    fetch(`https://us-central1-npopinth.cloudfunctions.net/resume/subcribe?email=${this.email}`);
    console.log(`https://us-central1-npopinth.cloudfunctions.net/resume/subcribe?email=${this.email}`);
    return false;
  }



}
