import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  portfolioData:any;
  isLoaded = false;
  loadedUrl = '';
  name:string;

  constructor(private http:HttpClient, private route:ActivatedRoute) { 
    this.route.params.subscribe(p => {
      this.name = p['view'];
      this.loadedUrl = `https://us-central1-npopinth.cloudfunctions.net/portfolio?view=${p['view']}`;
    })
  }

   titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }


  async ngOnInit() {

    this.portfolioData = await this.http.get(this.loadedUrl).toPromise();
    console.log(this.portfolioData)
    this.isLoaded = true;
  }

}
