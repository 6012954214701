import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  isShow = true;
  constructor(private router:Router, private route:ActivatedRoute) { }

  ngOnInit() {
    this.router.events.subscribe(event => {

      if(event['url']){
        let result = (event['url'] as string).match(/portfolio/g)
        if(result && result[0] == 'portfolio')
          this.isShow = false;
        else if(event['url'] && event['url'] === '/resume' || event['url'] && event['url'] === '/portfolio' )
          this.isShow = false
        else 
          this.isShow = true
      }
      
     // console.log('isShow', this.isShow)
    })
    //console.log(this.router.url)
    //this.isShow = this.router.url !== '/resume'
  }

}
